<template>
  <!-- 子账号管理 -->
  <div class="page-container">
    <div class="page-top-tool">
      <el-button type="success" icon="el-icon-plus" size="mini" @click="toAddSonAccountPage">新增子账号</el-button>
      <div class="right-search">
        <span>账号：</span>
        <el-input v-model="username" clearable placeholder="请输入" size="mini" style="width: 230px;"></el-input>
        <span style="margin-left: 10px;">员工姓名：</span>
        <el-input v-model="ename" clearable placeholder="请输入" size="mini" style="width: 230px;"></el-input>
        <el-button type="primary" icon="el-icon-search" size="mini" style="margin: 0px 20px;" @click="onSearch">搜索</el-button>
        <el-radio-group v-model="state" size="mini">
          <el-radio-button label="1">所有账号</el-radio-button>
          <el-radio-button label="2">只看启用</el-radio-button>
          <el-radio-button label="3">只看停用</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 100%; font-size: 12px; margin: 20px 0px;"
      :header-cell-style="tableHeaderColor"
    >
      <el-table-column
        prop="username"
        label="账号">
      </el-table-column>
      <el-table-column label="角色">
        <template slot-scope="scope">
          <span v-if="scope.row.prower == '1'">超级管理员</span>
          <span v-else-if="scope.row.prower == '2'">子管理员</span>
          <span v-else-if="scope.row.prower && !['1','2'].includes(scope.row.prower)">{{ scope.row.prower }}审核员</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.state == '1'" type="success" size="mini">启用</el-tag>
          <el-tag v-else type="info" size="mini">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="员工姓名" prop="ename"></el-table-column>
      <el-table-column label="手机号" prop="phone"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="onUpdateState(scope)">
            {{ scope.row.state == '1' ? '停用' : '启用' }}
          </el-button>
          <el-button type="text" size="mini" @click="onResetPwd(scope)">重置密码</el-button>
          <el-button type="text" size="mini" @click="onUpdate(scope)">编辑</el-button>
          <el-button type="text" size="mini" icon="el-icon-delete" @click="onDelete(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      hide-on-single-page
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

  </div>
</template>

<script>
export default {
  name: 'SonAccountPage',
  data() {
    return {
      username: '', // 网点名称
      ename: '', // 员工姓名
      state: '1', // 账号状态，1所有，2启用，3停用
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false
    };
  },
  watch: {
    state() {
      this.currentPage = 1
      this.getData()
    }
  },
  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      const params = {
          username: this.username,
          ename: this.ename,
          page: this.currentPage,
          pageSize: this.pageSize
      }
      if (this.state == '2') {
        params.state = '1'
      } else if (this.state == '3') {
        params.state = '2'
      }
      this.loading = true
      this.$http({
        method: 'post',
        url: 'sysuser_find',
        params
      })
      .then(res => {
        this.loading = false
        if (res.errno == 0) {
          const { data, page, pageSize, count } = res.data
          this.tableData = data
          this.currentPage = page
          this.pageSize = pageSize
          this.total = count
        } else {
          this.$message.error('系统账号查询失败')
        }
      })
      .catch(err => {
        this.loading = false
        this.$message.error('系统账号查询异常')
        console.error('系统账号查询异常：', err.message)
      })
    },
    toAddSonAccountPage() {
      this.$router.push({
        path: '/addSonAccount'
      })
    },
    tableHeaderColor({row, column, rowIndex, columnIndex}) {
      return 'background-color: #F2F2F2;color:#101010;'
    },
    onSearch() {
      this.currentPage = 1
      this.getData()
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.getData()
    },
    onUpdateState({ row, $index }) {
      const state = row.state == '1' ? '2' : '1'
      this.$confirm(`确定要${row.state == '1' ? '停用' : '启用'}账号 ${row.username} 吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.$http({
          method: 'post',
          url: 'sysuser_update',
          params: {
            id: row.id,
            state
          }
        })
        .then(res => {
          this.loading = false
          if (res.errno == 0) {
            this.$message.success('操作成功')
            this.getData()
          } else {
            this.$message.error('操作失败')
          }
        })
        .catch(err => {
          this.loading = false
          this.$message.error('操作异常')
        })
      }).catch(() => {});
    },
    onResetPwd({ row, $index }) {
      this.$prompt('请输入新密码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          if (value) {
            this.$confirm(`确定要重置账号 ${row.username} 的密码?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.loading = true
              this.$http({
                method: 'post',
                url: 'sysuser_update',
                params: {
                  id: row.id,
                  pwd: value
                }
              })
              .then(res => {
                this.loading = false
                if (res.errno == 0) {
                  this.$message.success('操作成功')
                } else {
                  this.$message.error('操作失败')
                }
              })
              .catch(err => {
                this.loading = false
                this.$message.error('操作异常')
              })
            }).catch(() => {});
          } else {
            this.$message.warning('没有输入任何内容');
          }
        }).catch(() => {});
    },
    onUpdate({ row, $index }) {
      sessionStorage.setItem('updateSonAccount', JSON.stringify(row))
      this.$router.push({
        path: '/updateSonAccount',
        query: {
          id: row.id
        }
      })
    },
    onDelete({ row, $index }) {
      this.$confirm(`确定要删除账号 ${row.username} 吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.$http({
          method: 'post',
          url: 'sysuser_delete',
          params: {
            id: row.id
          }
        })
        .then(res => {
          this.loading = false
          if (res.errno == 0) {
            this.$message.success('操作成功')
            this.getData()
          } else {
            this.$message.error('操作失败')
          }
        })
        .catch(err => {
          this.loading = false
          this.$message.error('操作异常')
        })
      }).catch(() => {});
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}
</style>